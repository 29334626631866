import React from 'react';
import ModalBenchmarkComponent from '../Component/ModalBenchmarkComponent';
import { useMutation } from '@tanstack/react-query';
import { mutateBenchmarkAPI } from '../../../Services/uploadBenchmark';
import { toast } from 'react-toastify';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  dataIsExist: boolean;
  handleRefresh: () => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalBenchmarkContainer(props: IProps) {
  const { dataIsExist, handleRefresh, showModal, setShowModal } = props;

  const { mutate, isLoading } = useMutation(mutateBenchmarkAPI, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">Berhasil Diupload</span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      handleCancel();
    },
    onError: (error: any) => {
      ErrorHandler(error, true);
    },
  });

  const handleCancel = () => {
    setShowModal(false);
    handleRefresh();
  };

  const handleUpload = (val: any) => {
    mutate({ ...val, isReplace: dataIsExist });
  };

  return (
    <ModalBenchmarkComponent
      dataIsExist={dataIsExist}
      handleCancel={handleCancel}
      handleUpload={handleUpload}
      isLoading={isLoading}
      showModal={showModal}
    />
  );
}
