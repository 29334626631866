import instance from '../Services/interceptor';

export const getBenchmarkListAPI = async () => {
  const { data } = await instance.get(
    `/Candidate/file/upload/history?uploadHistoryType=5`
  );
  return data;
};

export const mutateBenchmarkAPI = async (params: any) => {
  const { isReplace, fileBenchmark } = params;
  const formData = new FormData();
  const fileDetail = fileBenchmark[0];
  formData.append('originalFilename', fileDetail.name);
  formData.append('file', fileDetail.file);
  const { data } = await instance.post(
    `/Candidate/file/upload/benchmark?isReplace=${isReplace}`,
    formData
  );
  return data;
};
