import React from 'react';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import CCard from '../../../Assets/Components/CCard';
import CButton from '../../../Assets/Components/CButton';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';

interface IProps {
  benchmarkList: any[];
  columnData: any;
  handleRefresh: () => void;
  isLoadingList: boolean;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
}

export default function BenchmarkComponent(props: IProps) {
  const {
    benchmarkList,
    columnData,
    handleRefresh,
    isLoadingList,
    setShowModal,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2',
      icon: 'UploadOutlined',
      onClick: () => setShowModal(true),
      content: 'Unggah',
      id: 'btnUploadSchedule',
    },
    {
      type: 'ghost',
      icon: 'ReloadOutlined',
      onClick: handleRefresh,
      content: 'Refresh',
      id: 'btnRefreshUploadList',
    },
  ];

  const cardContent = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={isLoadingList}
        />
        <Table
          isLoading={isLoadingList}
          columns={generateColumnData(columnData)}
          data={benchmarkList}
          pagination={true}
          useFilterGlobal
        />
      </div>
    );
  };
  return (
    <>
      <CLabelCard leftText="Upload Benchmark" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">Benchmark Daily, Weekly & Monthly</span>
        }
        cardContent={cardContent()}
      />
    </>
  );
}
