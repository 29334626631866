import React, { useState } from 'react';
import BenchmarkComponent from '../Component/BenchmarkComponent';
import CButton from '../../../Assets/Components/CButton';
import ModalBenchmarkContainer from './ModalBenchmarkContainer';
import { useQuery } from '@tanstack/react-query';
import { getBenchmarkListAPI } from '../../../Services/uploadBenchmark';
import ModalDetailBenchmarkContainer from './ModalDetailBenchmarkContainer';
import moment from 'moment';

export default function BenchmarkContainer() {
  const [showModal, setShowModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const {
    data: benchmarkList = [],
    isFetching,
    refetch,
  } = useQuery(['getBenchmarkListAPI'], getBenchmarkListAPI, {
    // refetchOnWindowFocus: false
  });

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const dataButton = [
      {
        type: 'text',
        id: 'btnDetail',
        className: 'btnDetailQa',
        content: 'Detail',
        onClick: () => {
          setSelectedData(rows);
          setShowDetail(true);
        },
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const renderStatus = (row: any) => {
    return (
      <div className="spanContestStatus">
        <span
          className={`uploadScheuleStatusColor uploadScheuleStatusColor${row.row.original.status}`}
        />
        <span className={`uploadScheduleStatusName`}>
          {row.row.original.status}
        </span>
      </div>
    );
  };

  const handleRefresh = () => refetch();

  const columns = [
    {
      Header: 'Nama Berkas',
      accessor: 'originalFilename',
    },
    {
      Header: 'Status',
      Cell: (row: any) => renderStatus(row),
      disableSort: true,
    },
    {
      Header: 'Upload Date',
      accessor: (row: any) => moment(row.uploadDate).format('DD-MMM-YYYY'),
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];

  return (
    <>
      <BenchmarkComponent
        benchmarkList={benchmarkList}
        columnData={columns}
        handleRefresh={handleRefresh}
        isLoadingList={isFetching}
        showModal={showModal}
        setShowModal={setShowModal}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
      {showModal && (
        <ModalBenchmarkContainer
          dataIsExist={benchmarkList.length > 0}
          handleRefresh={handleRefresh}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {showDetail && (
        <ModalDetailBenchmarkContainer
          selectedData={selectedData}
          showModal={showDetail}
          setShowModal={setShowDetail}
        />
      )}
    </>
  );
}
