import React from 'react';
import { toastr } from 'react-redux-toastr';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { Button } from 'antd';
import { Field, FormRenderProps } from 'react-final-form';
import InputFile from '../../../Assets/Components/InputFile';
import { UploadOutlined } from '@ant-design/icons';

interface IProps {
  dataIsExist: boolean;
  handleCancel: () => void;
  handleUpload: (val: any) => void;
  isLoading: boolean;
  showModal: boolean;
}

export default function ModalBenchmarkComponent(props: IProps) {
  const { dataIsExist, handleCancel, showModal, handleUpload, isLoading } =
    props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <span>New Upload</span>
      </h3>
    );
  };

  const contentComponent = (formProps: FormRenderProps<any, any>) => {
    const { values } = formProps;

    const toastrConfirmOptions = {
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        console.log('letsgo');
        handleUpload(values);
      },
      id: 'toastRConfirm',
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Field
          name="fileBenchmark"
          component={InputFile}
          idComponent="uppyUploadBenchmark"
          uppyConfig={{
            minFile: 1,
            maxFile: 1,
            allowedFile: ['.xlsx'],
            maxSize: 5242880,
          }}
          useFile={true}
          height={300}
        />
        <Button
          type="primary"
          onClick={() => {
            if (dataIsExist)
              toastr.confirm(
                `Existing data will be replace with new data, are you sure to proceed?`,
                toastrConfirmOptions
              );
            else handleUpload(values);
          }}
          id="btnUpload"
          icon={<UploadOutlined />}
          disabled={!values?.fileBenchmark}
          loading={isLoading}
          className="btnRadius10"
        >
          Upload
        </Button>
      </div>
    );
  };

  return (
    <CModalRFF
      modalIsShow={showModal}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={null}
      handleCancel={handleCancel}
      handleValidation={() => {}}
      initialValues={null}
      isForm={true}
      handleSubmit={() => {}}
      formLayout="vertical"
      modalstyles={{
        content: {
          position: 'relative',
          background: 'none',
          maxWidth: '95vw',
          width: '500px',
          padding: '0px',
          border: 'none',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '99',
        },
      }}
    />
  );
}
