import React from 'react';
import ModalDetailBenchmarkComponent from '../Component/ModalDetailBenchmarkComponent';

interface IProps {
  selectedData: any;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
}

export default function ModalDetailBenchmarkContainer(props: IProps) {
  const { showModal, setShowModal, selectedData } = props;

  const handleCancel = () => setShowModal(false);

  return (
    <ModalDetailBenchmarkComponent
      handleCancel={handleCancel}
      showModal={showModal}
      selectedData={selectedData}
    />
  );
}
